body{
    background: #f0f0f0;
    font-family: Verdana,Geneva,sans-serif;
    word-wrap: break-word;
    
    margin: 35px 0px 50px;
    font-size: 16px;
    .panel{
        box-shadow: 1px 1px 3px 2px #ccc;
        border: 1px solid #aaa;
        border-radius: 0px;
        .panel-heading{
            border-radius: 0px;
            .panel-title{
                font-size: 25px;
            }
        }
        .panel-body{
            padding: 15px 15px 20px;
            :last-child{
                margin-bottom: 0px;
            }
        }
        &.panel-default{
            .panel-heading{
                background: none;
            }
        }
        &.panel-success{
            .panel-heading{
                background: #43a047;
                color: #fff;
            }
        }
        .panel-footer{
            border-top: 1px solid #aaa;
        }
    }
    .btn{
        border-radius: 0px;
        letter-spacing: 1px;
        text-transform: uppercase;
        box-shadow: 1px 1px 1px 1px #ddd;
        border-color: #424242;
        &.btn-primary{
            background: #000;
            color: #fff;
            border-color: #999;
        }
        &.btn-default{
            box-shadow: none;
            background: #f0f0f0;
            border-color: #aaa;
        }
        &.btn-link{
            border: 0px;
            box-shadow: none;
            font-size: 16px;
            color: #1a237e;
            &:hover{
                color: #1a237e;
            }
        }
    }
    .alert{
        border-radius: 0px;
        border-style: solid;
        border-width: 3px;
        color: #000;
        padding: 20px 15px;
        font-size: 20px;
        &.alert-success{
            border-color: #1b5e20;
            background: #e8f5e9;
        }
        &.alert-danger{
            border-color: #b71c1c;
            background: #ffebee;
        }
        &.alert-info{
            border-color: #01579b;
            background: #e1f5fe;
        }
    }
}

.material-icons{
    display: inline-flex;
    vertical-align: middle;
    cursor: inherit;
    &.md-required{
        font-size: 80%;
        vertical-align: top;
    }
}